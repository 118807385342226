import { makeVar } from '@apollo/client';

export const SORT_OPTIONS = {
  TITLE_ASC: {
    languageKey: 'title-asc',
    value: 'title_ASC',
  },
  TITLE_DESC: {
    languageKey: 'title-desc',
    value: 'title_DESC',
  },
  AUTHOR_ASC: {
    languageKey: 'author-asc',
    value: 'author_ASC',
  },
  AUTHOR_DESC: {
    languageKey: 'author-desc',
    value: 'author_DESC',
  },
  RELEASE_DATE_ASC: {
    languageKey: 'release-date-asc',
    value: 'publish-year_ASC',
  },
  RELEASE_DATE_DESC: {
    languageKey: 'release-date-desc',
    value: 'publish-year_DESC',
  },
};

export const defaultValue = {
  records: [],
  recordCount: 0,
  includedProfiles: [],
  includedIndexEntries: [],
  formatObject: undefined,
  selectedFormat: 'default',
  tabValue: 1,
  primaryTemplateId: null,
  page: 0,
  itemPerPage: 10,
  aggregations: {},
  filters: {},
  loading: false,
  selectedRecords: [],
};

export const bibSearchResultVar = makeVar(defaultValue);
