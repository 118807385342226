import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { v4 as uuid } from 'uuid';
import { Container, Grid, Box, makeStyles } from '@material-ui/core';
import styles from './index.module.scss';
import BasicLayout from '../layouts/basic_layout';
import Head from '../components/head';
import { graphql, useStaticQuery } from 'gatsby';
import MainSearch, {
  MAIN_SEARCH_URL_STATE_DEFAULT_VALUE,
  searchQueryDefaultValue,
} from '../components/MainSearch/MainSearch';
import { GET_SEARCH_FIELDS } from '../apollo/queries/searchRelatedQueries';
import { encodeQueryParams, useQueryParams } from 'use-query-params';
import { useLazyQuery } from '@apollo/client';
import MediaKit from '../assets/MediaKit';
import { controlledFeatures } from '../utils/featureControl';

const useStyles = makeStyles({
  topContainer: {
    backgroundImage: `url(${MediaKit.body?.backgroundImageSrc})`,
  },
});

const IndexPage = (props) => {
  const [defaultUrlState, setDefaultUrlState] = useQueryParams(
    MAIN_SEARCH_URL_STATE_DEFAULT_VALUE
  );
  const [urlState, setUrlState] = useState(null); //FIRST VALUE SET IN USEEFFECT

  const [getSearchFields] = useLazyQuery(GET_SEARCH_FIELDS, {
    onCompleted: (data) => {
      const titleSearchField = data.searchFields_v2.nodes.find(
        ({ slug }) => slug === 'main-title'
      );
      const authorSearchField = data.searchFields_v2.nodes.find(
        ({ slug }) => slug === 'author'
      );
      const subjectSearchField = data.searchFields_v2.nodes.find(
        ({ slug }) => slug === 'subject'
      );

      const newSearchQuery = [
        {
          id: 'firstRow',
          type: 'begin_with',
          searchField: titleSearchField,
          searchValue: '',
        },
        {
          id: uuid(),
          operator: 'and',
          type: 'begin_with',
          searchField: authorSearchField,
          searchValue: '',
        },
        {
          id: uuid(),
          operator: 'and',
          type: 'begin_with',
          searchField: subjectSearchField,
          searchValue: '',
        },
      ];

      setUrlState((prevUrlState) => {
        const newState = {
          ...prevUrlState,
          searchField: titleSearchField,
          searchQuery: newSearchQuery,
        };

        setDefaultUrlState(newState);
        return newState;
      });
    },
  });

  useEffect(() => {
    if (!controlledFeatures.recordList) {
      return;
    }

    const newUrlState = {
      tabValue: defaultUrlState.tabValue ? defaultUrlState.tabValue : 0,
      searchField: defaultUrlState.searchField || null,
      searchValue: defaultUrlState?.searchValue || null,
      searchQuery: defaultUrlState.searchQuery || searchQueryDefaultValue,
    };

    if (newUrlState?.searchQuery.length !== 0) {
      setUrlState(newUrlState);
      return;
    }

    getSearchFields();
  }, []);

  const query = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { title: { eq: "Katalógus" } }) {
        frontmatter {
          title
        }
        html
      }
    }
  `);

  const classes = useStyles();

  if (!urlState && controlledFeatures.recordList) {
    // AZÉRT VAN ERRE SZÜKSÉG, MERT NULLKÉNT VAN DEFINIÁLVA ÉS ELSŐ USE EFFECT ÁLLÍTJA BE VALUET, ADDIG NEM SZERETNÉNK FELÜLETET MUTATNI
    return null;
  }

  return (
    <BasicLayout>
      <Head
        title={
          controlledFeatures.recordList
            ? query.markdownRemark.frontmatter.title
            : 'Főoldal'
        }
      />
      <Grid
        className={styles.mainContainer}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Box
          className={`${styles.topContainer} ${classes.topContainer}`}
          style={{ height: '70vh' }}
          component="section"
        >
          <Container className={styles.topContainerInner} maxWidth="lg">
            <div
              className={styles.searchBarWrapper}
              style={{
                marginTop: '3rem',
              }}
            >
              {/* <SearchBar /> */}
              {controlledFeatures.recordList && (
                <MainSearch
                  urlState={urlState}
                  setUrlState={setUrlState}
                  searchClickedHandler={() => {
                    const queryParams = encodeQueryParams(
                      MAIN_SEARCH_URL_STATE_DEFAULT_VALUE,
                      urlState
                    );

                    if (
                      +queryParams?.tabValue === 0 &&
                      (queryParams?.searchField === undefined ||
                        queryParams?.searchField === null)
                    )
                      return;

                    const queryString = Object.keys(queryParams).reduce(
                      (a, x, index) =>
                        `${a}${index > 0 ? '&' : ''}${x}=${encodeURIComponent(
                          queryParams[x]
                        )}`,
                      '?'
                    );

                    navigate(`/record_list${queryString}`);
                  }}
                />
              )}
            </div>
          </Container>
        </Box>
        {/* <Box className={styles.pageContentContainer} component="section">
          <Container maxWidth="md">
            <div className={styles.textContentContainer} dangerouslySetInnerHTML={{ __html: query.markdownRemark.html }}></div>
          </Container>
        </Box> */}
      </Grid>
    </BasicLayout>
  );
};

export default IndexPage;
